import React from "react";
import styled from "styled-components";
import Scrollchor from "react-scrollchor";
import { useInView } from "react-intersection-observer";


const Wrapper = styled.div`

@media ${props => props.theme.mq.md} {
  display:none;
  background-color: white;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  box-shadow: 3px 4px 8px #00000029;

  &.show {
    display:block;
  }

}

.nav{
  ${props => props.theme.wrappersContainers.primaryWrapper};
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  .mobileTitle {
    white-space: nowrap;
    padding: 1rem 1rem 1rem 0;
    @media ${props => props.theme.mq.md} {
      display: none;
    }
  }

  a {
    white-space: nowrap;
    padding: 1rem;
    display: inline-block;
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
}


`

const StickyNavigation = ({ bgData, view }) => {

    const { ref, inView /*, entry*/ } = useInView({
        /* Optional options */
        threshold: 0,
        rootMargin: '410px',
      });

    return (
        <div ref={ref}>
        <Wrapper className={inView ? inView : "show"} >

            <div className="nav">
                <div className="mobileTitle">Skip to</div>

                <Scrollchor
                    to="#overview"
                    animate={{ offset: -40, duration: 600 }}
                    disableHistory={true}
                >
                    Overview
            </Scrollchor>

                <Scrollchor
                    to="#particulars"
                    animate={{ offset: -40, duration: 600 }}
                    disableHistory={true}
                >
                    Facts &amp; Features
            </Scrollchor>

                <Scrollchor
                    to="#location"
                    animate={{ offset: -40, duration: 600 }}
                    disableHistory={true}
                >
                    Location
            </Scrollchor>

                <Scrollchor
                    to="#availability"
                    animate={{ offset: -40, duration: 600 }}
                    disableHistory={true}
                >
                    Availability
            </Scrollchor>

                {bgData.reviews && (
                    <Scrollchor
                        to="#reviews"
                        animate={{ offset: -40, duration: 600 }}
                        disableHistory={true}
                    >
                        Reviews
                    </Scrollchor>
                )}

                <Scrollchor
                    to="#related"
                    animate={{ offset: -40, duration: 600 }}
                    disableHistory={true}
                >
                    Related
            </Scrollchor>

            </div>
        </Wrapper>
        </div>
    )

}


export default StickyNavigation