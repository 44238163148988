import React, { useState } from "react"
import styled from "styled-components"
import StarRating from "./starRating"

const Wrapper = styled.div`
  max-width: 682px;
  display:flex;
  flex-wrap: wrap;
  .feefo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom:2rem;
      
  }
  .ve{
      max-height:8rem;
      max-width: 8rem;
      margin-right: 2rem;    
  }
  
  .svgWrapper.yes path {
      fill: #ffde00;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
`
const Rating = styled.div`
  display: flex;
  svg {
    height: 20px;
    margin-right: 3px;
    path {
      fill: ${props => props.theme.colors.secondary};
    }
  }
`
const Review = styled.div`
  margin: 0.3rem auto;
  @media ${props => props.theme.mq.md} {
    margin: 2rem auto 2rem 0;
  }
  h4 {
    margin: 0 0 0 0.2rem;
    font-weight: 700;
  }
`

const Thread = styled.div`
  margin: 2rem auto 2rem 0.5rem;
  &.merchant {
    position: relative;
    border-left: 2px solid ${props => props.theme.colors.primary};
    padding-left: 0.8rem;
    h4 {
      color: ${props => props.theme.colors.primary};
    }
  }
  h4 {
    margin: 0;
  }
`

const More = styled.a`
  color: ${props => props.theme.colors.secondary};
  &:hover {
    cursor: pointer;
  }
`

const Content = styled.div``

const Reviews = props => {
  const { rating, reviews } = props

  const init = props.reviews !== null ? props.reviews.slice(0, 1) : []
  const [reviewState, setReviewState] = useState(init)

  const getThread = threads => {
    return threads !== null || threads !== "undefined"
      ? threads.map((thread, i) => {
        return (
          <Thread key={i} className={thread.type !== null ? thread.type : ""}>
            <Header>
              <h4>
                {thread.type !== null
                  ? props.merchant + " Response"
                  : thread.reviewer}
              </h4>
            </Header>
            <Content>
              <div dangerouslySetInnerHTML={{ __html: thread.comment }}></div>
            </Content>
          </Thread>
        )
      })
      : ""
  }

  const checkReviews =
    props.reviews !== null ||
      (props.reviews !== "undefined" &&
        props.reviews.length - reviewState.length > 0)
      ? props.reviews
      : []

  const totalReviews = checkReviews ? (

    <More onClick={e => setReviewState(props.reviews)}>
      {props.reviews.length - reviewState.length > 0 ? props.reviews.length - reviewState.length + " more" : ""}
    </More>

  ) : (
      ""
    )

  const getReview = reviewState.map((review, i) => {
    return (
      <Review key={i}>
        <Header>
          <Rating>
            <StarRating rating={review.rating} />
          </Rating>
          <h4>{review.reviewer}</h4>
        </Header>
        <Content>
          <div dangerouslySetInnerHTML={{ __html: review.comment }}></div>
          {review.thread !== null ? getThread(review.thread) : ""}
        </Content>
      </Review>
    )
  })

  return (
    <Wrapper>
       {rating > 0 &&
          <div className="feefo">
              <img className="ve" alt="Feefo Logo" src="/images/feefo_logo.png" />
              <span><StarRating rating={rating} /></span>
          </div>
      }
      {getReview}
      {totalReviews}
    </Wrapper>
  )
}

export default Reviews
