import React, { useContext, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import PropTypes from "prop-types"
import momentPropTypes from "react-moment-proptypes"
import moment from "moment"
import { forbidExtraProps } from "airbnb-prop-types"
import { DayPickerRangeController } from "react-dates"
import CustomizableCalendarDay from "react-dates/lib/components/CustomizableCalendarDay"
import { defaultStyles, selectedStyles, /*selectedSpanStyles,*/ hoveredSpanStyles /*,blockedCalendarStyles */} from "react-dates/lib/components/CustomizableCalendarDay"
import styled from "styled-components"
import Icon from "../../utils/icon"
//css
import 'react-dates/lib/css/_datepicker.css';
import "react-dates/initialize"
import {
  START_DATE,
  END_DATE,
  // HORIZONTAL_ORIENTATION,
  //VERTICAL_ORIENTATION,
} from "./constants"
import { WindowDimensionsCtx } from "../../utils/windowDimensionsProvider"

import colors from "../../theme/colors"

const DatesWrapper = styled.div.attrs({
  className: "datesWrapper",
})`
  text-align:center;
  height:3rem;
  .reset{
    margin-left:0.5rem;
    border: 1px solid ${props => props.theme.colors.lightGrey};
    padding:0.5rem 1rem;
    display:inline;
    width: auto;
    cursor:pointer;
  }

  .svgWrapper{
    display:inline-block;
    position:relative;
    top:2px;
    left:-3px;
    fill: ${props => props.theme.colors.secondary};
  }
`




//Colours
const blockedPrimary = '#cacccd';
const blockedHover = blockedPrimary;
const selectedPrimary = colors.secondary;
const selectedHover = `rgba(${colors.secondaryRGB} ,0.5)`;
const changeoverPrimary = 'white';
const changeoverHover = `rgba(${colors.secondaryRGB} ,0.5)`;


//changeover
const changeoverDefaultStyle = {
  background: changeoverPrimary,
  color: colors.primaryFontColor,
  hover: {
    background: changeoverHover,
    color: colors.primaryFontColor,
    border: `1px solid #e4e7e7`,
  },
};


//selected day
const changeoverSelectedStyle = {
  border: `1px double white`,
  background: selectedPrimary,
  color: `white`,
  hover: {
    border: `1px double white`,
    background: selectedPrimary,
    color: `white`,
  },
};

//select hover
const changeoverHoveredSpanStyle = {
  border: `1px double white`,
  background: selectedHover,
  color: colors.primaryFontColor,
  hover: {
    border: `1px double white`,
    background: selectedHover,
    color: colors.primaryFontColor,
  },
};



//start of booked dates
const bookingStartDefaultStyle = {
  background: `linear-gradient(135deg, ${changeoverPrimary} 0%, ${changeoverPrimary} 50%, ${blockedPrimary} 50%, ${blockedPrimary} 100%)`,
  hover: {
    background: `linear-gradient(135deg, ${changeoverHover} 0%, ${changeoverHover} 50%, ${blockedHover} 50%, ${blockedHover} 100%)`,
  },
};
const bookingEndDefaultStyle = {
  background: `linear-gradient(135deg, ${blockedPrimary} 0%, ${blockedPrimary} 50%, ${changeoverPrimary} 50%, ${changeoverPrimary} 100%)`,
  hover: {
    background: `linear-gradient(135deg, ${blockedHover} 0%, ${blockedHover} 50%, ${changeoverHover} 50%, ${changeoverHover} 100%)`,
  },
};

//end of booked dates
const bookingStartSelectedStyle = {
  background: `linear-gradient(135deg, ${selectedPrimary} 0%, ${selectedPrimary} 50%, ${blockedPrimary} 50%, ${blockedPrimary} 100%)`,
  border: `1px solid #e4e7e7`,
  hover: {
    background: `linear-gradient(135deg, ${selectedPrimary} 0%, ${selectedPrimary} 50%, ${blockedPrimary} 50%, ${blockedPrimary} 100%)`,
    border: `1px solid #e4e7e7`,
    color: `white`,
  },
};


//end of booked dates hover
const bookingEndSelectedStyle = {
  background: `linear-gradient(135deg, ${blockedPrimary} 0%, ${blockedPrimary} 50%, ${selectedPrimary} 50%, ${selectedPrimary} 100%)`,
  border: `1px solid #e4e7e7`,
  hover: {
    background: `linear-gradient(135deg, ${blockedPrimary} 0%, ${blockedPrimary} 50%, ${selectedPrimary} 50%, ${selectedPrimary} 100%)`,
    border: `1px solid #e4e7e7`,
    color: `white`,
  },
};

const bookingStartHoveredSpanStyle = {
  background: `linear-gradient(135deg, ${selectedHover} 0%, ${selectedHover} 50%, ${blockedPrimary} 50%, ${blockedPrimary} 100%)`,
  border: `1px solid #e4e7e7`,

  hover: {
    background: `linear-gradient(135deg,  ${selectedHover} 0%, ${selectedHover} 50%, ${blockedHover} 50%, ${blockedHover} 100%)`,
    border: `1px solid #e4e7e7`,
    color: `white`,
  },
};

const bookingEndHoveredSpanStyle = {
  background: `linear-gradient(135deg, ${blockedPrimary} 0%, ${blockedPrimary} 50%, ${selectedHover} 50%, ${selectedHover} 100%)`,
  border: `1px solid #e4e7e7`,

  hover: {
    background: `linear-gradient(135deg,  ${blockedHover} 0%, ${blockedHover} 50%, ${selectedHover} 50%, ${selectedHover} 100%)`,
    border: `1px solid #e4e7e7`,
    color: `white`,
  },
};

const customiseStartStyles = {
  defaultStyles: { ...defaultStyles, ...bookingStartDefaultStyle },
  hoveredSpanStyles: { ...hoveredSpanStyles, ...bookingStartHoveredSpanStyle },
  selectedStyles: { ...selectedStyles, ...bookingStartSelectedStyle },
  // left for information taken from CustomizableCAlendarDay.jsx
  //outsideStyles: highlightStyles,
  //todayStyles: highlightStyles,
  //firstDayOfWeekStyles: highlightStyles,
  //lastDayOfWeekStyles: highlightStyles,
  //highlightedCalendarStyles: highlightStyles,
  //blockedMinNightsStyles: highlightStyles,
  //blockedCalendarStyles: highlightStyles,
  //blockedOutOfRangeStyles: highlightStyles,
  //selectedSpanStyles: {...selectedSpanStyles,...highlightStyles},
  //lastInRangeStyles: highlightStyles,
  //selectedStartStyles: selectedStyles,
  //selectedEndStyles: selectedStyles,
  //afterHoveredStartStyles: DayStyleShape,
  //hoveredStartFirstPossibleEndStyles: DayStyleShape,
  //hoveredStartBlockedMinNightsStyles: DayStyleShape,
  // selectedSpanStyles: {...changeoverSelectedSpanStyle},
};

const customiseEndStyles = {
  defaultStyles: { ...defaultStyles, ...bookingEndDefaultStyle },
  hoveredSpanStyles: { ...hoveredSpanStyles, ...bookingEndHoveredSpanStyle },
  selectedStyles: { ...selectedStyles, ...bookingEndSelectedStyle },
};

const customiseChangeoverStyles = {
  defaultStyles: { ...defaultStyles, ...changeoverDefaultStyle },
  hoveredSpanStyles: { ...hoveredSpanStyles, ...changeoverHoveredSpanStyle },
  selectedStyles: { ...selectedStyles, ...changeoverSelectedStyle },
};

function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false

  const aYear = a.year()
  const aMonth = a.month()

  const bYear = b.year()
  const bMonth = b.month()

  const isSameYear = aYear === bYear
  const isSameMonth = aMonth === bMonth

  if (isSameYear && isSameMonth) return a.date() < b.date()
  if (isSameYear) return aMonth < bMonth
  return aYear < bYear
}

function isInclusivelyAfterDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false
  return !isBeforeDay(a, b)
}

const BOOKED = 0;
const FREE = 1;
const FREE_TO_ARRIVE = 3;
const FREE_TO_LEAVE = 2;
const MAXIMUM_DAYS = 21;

const propTypes = forbidExtraProps({
  propRef: PropTypes.string,
  onChange: PropTypes.func,
  //  onReset: PropTypes.func,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
})

const AvailabilityPicker = ({
  propRef,
  onChange,
  //                              onReset,
  initialStartDate,
  initialEndDate,
}) => {

  // This query is executed at run time by Apollo.
  const APOLLO_QUERY = gql`
    query getMyDates($searchquery: SearchQuery!) {
      getAvailability(query: $searchquery) {
        totalCount
        days {
          day
          status
          changeover
        }
      }
    }

    `

  // the range of dates to check against
  const [availDatesRange] = useState({
    min: moment(),
    max: moment().add(18, 'months'),
  });

  const bgAvailability = {
    which: {
      proprefs: [propRef]
    },
    when: {
      from: availDatesRange.min.toISOString(),
      to: availDatesRange.max.toISOString()
    },
  }

  // Our Apollo Client query that does the heavy lifting to fetch our data and returns data to loadDynamic handler
  const { data } = useQuery(APOLLO_QUERY, {
    variables: { searchquery: bgAvailability },
    ssr: false,
  })

  const availabilityState = { days: data?.getAvailability?.days };

  // Which input is currently focussed for entry
  const [focusedInput, setfocusedInput] = useState(START_DATE)

  const updateOnFocusChange = focusedInput => {
    // console.log("focus change");
    setfocusedInput(focusedInput ?? START_DATE);
  }

  //inout state - dates
  const [dateState, setDateState] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate
  })

  const updateOnDatesChange = ({ startDate, endDate }) => {
    if (focusedInput === START_DATE)
      endDate = null;
    setDateState({ startDate, endDate });

    // console.log("dates changed: ",startDate,endDate);
    if (focusedInput === END_DATE)
      onChange(startDate, endDate);

  }

  const onDatesReset = () => {
    const startDate = null, endDate = null;
    setfocusedInput(focusedInput ?? START_DATE);
    setDateState({
      startDate: startDate,
      endDate: endDate
    });
    onChange(startDate, endDate);
  }

  // ** For checking between dates MomentJS has the handy isBetween() method - moment(day).isBetween('2019-10-19', '2019-10-25') nice :)
  function matchOfTheDay(day) {
    if (!(day && availabilityState.days)) {
      return null;
    }
    let daytz = day.isDST()
      ? day
        .clone()
        .add(1, "hour")
      : day
        .clone()
        .startOf("day")

    daytz = daytz.format("YYYY-MM-DD")

    return availabilityState.days.find(targobj => targobj.day.substring(0, 10) === daytz)
  }

  // const changeOverDay = day => {

  //   const matched = matchOfTheDay(day)
  //   if (matched) {
  //     return (matched.status === FREE_TO_LEAVE || matched.status === FREE_TO_ARRIVE)
  //   }
  //   return false;
  // }

  const bookingStatus = day => {

    const matched = matchOfTheDay(day)
    if (matched) {
      return matched.status;
    }
    return FREE;
  }

  const checkBlocked = day => {

    const matched = matchOfTheDay(day)

    if (matched) {
      return matched.status === BOOKED
    }
    return false;
  }

  //const { renderCalendarInfo: renderCalendarInfoProp } = { ...props }

  //const { errorMessage, focusedInput } = { ...datePicker }

  // const renderCalendarInfo = errorMessage
  //   ? () => <div>{errorMessage}</div>
  //   : renderCalendarInfoProp

  //=========================================================================
  // Render
  //format input dates
  const startDateString = dateState.startDate && dateState.startDate.format('DD MMM');
  const endDateString = dateState.endDate && dateState.endDate.format('DD MMM YYYY');

  const calendarDay = (props) => {
    const { day } = props;
   // const { modifiers } = props;

    const status = bookingStatus(day);

    switch (status) {
      case FREE:
        return (<CustomizableCalendarDay {...props} {...customiseChangeoverStyles}></CustomizableCalendarDay>);
      case FREE_TO_LEAVE:
        return (<CustomizableCalendarDay {...props} {...customiseEndStyles}></CustomizableCalendarDay>);
      case FREE_TO_ARRIVE:
        return (<CustomizableCalendarDay {...props} {...customiseStartStyles}></CustomizableCalendarDay>);
      default:
        return (<CustomizableCalendarDay {...props} ></CustomizableCalendarDay>);
    }
  }

  const mediaContext = useContext(WindowDimensionsCtx)

  const isMobile = () => {
    if (mediaContext.width > 639) {
      return false
    } else {
      return true
    }
  }

  return (
    <div style={{ height: "100%" }}>
      <DatesWrapper>
        {endDateString && (
          <>
            <span>{startDateString}</span> to <span>{endDateString}</span>
            <div className="reset" onClick={onDatesReset}><Icon name="close" height="16px" />Reset dates</div>
          </>
        )}
      </DatesWrapper>

      <DayPickerRangeController
        minDate={availDatesRange.min}   //sets the min/max date navigatable on the calendars
        maxDate={availDatesRange.max}
        startDate={dateState?.startDate}  // from momentPropTypes.momentObj or null,
        endDate={dateState?.endDate}      // to momentPropTypes.momentObj or null,
        onDatesChange={updateOnDatesChange} // PropTypes.func.isRequired,
        focusedInput={focusedInput}         // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={updateOnFocusChange} // PropTypes.func.isRequired,
        isDayBlocked={checkBlocked}
        isOutsideRange={(day) => (
            (focusedInput === END_DATE && (day.isBefore(dateState?.startDate) || day.isAfter(dateState?.startDate.clone().add(MAXIMUM_DAYS, 'days'))))
            || (focusedInput === START_DATE && (!isInclusivelyAfterDay(day, moment()) || isInclusivelyAfterDay(day, availDatesRange.max)))
        )}
        numberOfMonths={isMobile() ? 1 : 2}
        hideKeyboardShortcutsPanel={true}
        noBorder={true}
        renderCalendarDay={calendarDay}
        transitionDuration={0}
      //    renderCalendarInfo={renderCalendarInfo}
      //    isDayHighlighted={changeOverDay}
      //    initialVisibleMonth={() => moment().add(2, "M")}
      />
    </div>
  )
}

AvailabilityPicker.propTypes = propTypes
// AvailabilityPicker.defaultProps = defaultProps

export default AvailabilityPicker
