import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  position:relative;
  display:flex;
  flex-wrap:wrap;

  input{
    display:none;
  }

  input + label {
      cursor: pointer;
      margin-top:1rem;
      order:2;
      &:before{
          content:"Read more";
          position: relative;
          font-weight: bold;
      }
  }

  .more{
      order:1;
      overflow:hidden;
      transition: all 0.3s ease-in-out;

      &:after{
          content: "";
          position: absolute;
          bottom: 2.2rem;
          width:100%;
          height:3rem;
          background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
          opacity: 1;
          transition: all 0.3s ease-in-out;
      }

  }

  .readMoreCheckbox:checked ~ .more { 
      max-height:20000px!important;
      &:after{
          opacity:0;
      }
  }

  .readMoreCheckbox:checked + label { 
      &:before{
          content:"Read less";
      }
  }

`

const ReadMore = ({ content, uniqueId, height }) => {

  return (
    <Wrapper>

      <input type="checkbox" name="readMore" className="readMoreCheckbox" id={'more' + uniqueId} />

      <label htmlFor={'more' + uniqueId}></label>

      <div className="more" style={{ maxHeight: height + 'px' }} dangerouslySetInnerHTML={{ __html: content }} />

    </Wrapper>
  );
};

export default ReadMore;