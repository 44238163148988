import React from "react";
import styled from "styled-components";
import Icon from "../../utils/icon";
import Heading from "../../utils/heading"


const Wrapper = styled.div`

`

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;

  @media ${props => props.theme.mq.xs} {
    grid-template-columns: 1fr 1fr;
  }

  li {
    padding: 0.1rem 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .svgWrapper {
      display: flex;
      align-items: center;
    }
    svg {
      fill: ${props => props.theme.colors.primary};
      max-height: 1rem;
      margin-right: 0.5rem;
    }
  }
`

const Attributes = ({ attrs }) => {

  function removeAttrs() {

    var attributes = attrs

    delete attributes['Suitability']

    delete attributes['Visit England Grading']

    return attributes

  }


  return (

    <>

      {Object.entries(removeAttrs()).map(([category, catglist], catgNo) =>

        <Wrapper key={catgNo}>

          <Heading as="h5" color={props => props.theme.colors.secondry}>
            {category}
          </Heading>

          <List>
            {catglist.map((cat, i) => {

              return (

                <li key={i}>
                  <Icon name="tick" height="1rem" />
                  <div>{cat.name} {cat.value === "true" ? '' : ': ' + cat.value}</div>
                </li>

              );

            })}
          </List>

        </Wrapper>

      )}

    </>

  )

}


export default Attributes;