import React from "react"
import { gql, useQuery } from "@apollo/client"
import styled from "styled-components"

import PropertySlider from "../propertySlider/propertySlider"
import Loading from "../../loadingAnim/loader"

const Wrapper = styled.div.attrs({
    className: "relatedPropertiesWrapper",
})``

const PropertySelector = props => {
  // This query is executed at run time by Apollo.
  const APOLLO_QUERY = gql`
  query getMyProperties($searchquery: SearchQuery) {
    getProperties(query: $searchquery) {
      properties {
        propRef: ref
        name
        wpurl
        rating
        prices {
          priceBase
        }
        description {
          short
        }
        sleeps
        bedrooms
        pets
        where: address {
          town
          coords {
            lat
            lon
          }
        }
        attributes{
          name
          value
        }
        images {
          description
          url
        }
        gallery {
          description
          url
        }
      }
    }
  }
`

  const searchquery = props.searchquery;

  // Our Apollo Client query that does the heavy lifting to fetch our data and returns data to loadDynamic handler
  const { loading, error, data } = useQuery(APOLLO_QUERY, {
    variables: {searchquery},
    //skip: !searchquery,
    ssr: false,
  })

  const processProperties = (rawList) => {
    if (rawList !== undefined) {
      return rawList.filter(property => (
        !props.excludeIds.includes(property.propRef)
      )).sort(() => Math.random() - 0.5)
    } else {
      return null
    }
  }



  if (error) return <div className="errorMsg">{error.message}</div>;
  if (loading) {
    return (
      <Loading>
          <div className="errorMsg">
              Sorry there are no associated properties with this search.
              <br />
              <br />
          </div>
      </Loading>
      )
  } else {
    return (
      <Wrapper>
          <PropertySlider properties={processProperties(data?.getProperties?.properties)} maxno={props.maxno} />
      </Wrapper>
    )
  }

}

export default PropertySelector
