import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components"
import { Link } from "@reach/router"
import SEO from "../utils/seo"
import PropertyPageInner from "../components/propertyPage/propertyPageInner";


const GoBack = styled.div.attrs({
  className: "breadcrumbsInner",
})`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media ${props => props.theme.mq.sm} {
    padding-left:3rem;
  }

  .linkWrapper{
    color: ${props => props.theme.colors.secondary};
    text-decoration:none;
    margin-right: 0.5rem;
    &:after {
        margin-left: 0.5rem;
        content: " / ";
        color: ${props => props.theme.colors.secondary};
      }
  }

  button{
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: ${props => props.theme.colors.secondary};
  }

`;

const PropertyPage = ({ data }) => {

  function goBack() {
    window.history.back();
  }

  return (
    <>

      <SEO
        title={data.wpProperty.title}
        description={
          data.wpProperty.content
        }
        image={data?.wpProperty?.featuredImage?.node.uri}
        canonical={data.wpProperty.uri}
        article={false}
      />

      <GoBack>
        <Link className="linkWrapper" to={"/"} title="Home">Home</Link>
        <button onClick={() => goBack()} title="Back">Back</button>
      </GoBack>

      {data.bookingGraph.getProperty &&
        <PropertyPageInner data={data} />
      }
    </>
  );
};

export default PropertyPage;

export const pageQuery = graphql`
  query($id: String!, $propId: Int! ) {
    wpProperty(id: { eq: $id }) {
      id
      databaseId
      title
      content
      uri
      propertyLocations {
        nodes {
          location_directions {
            directions
          }
        }
      }
    }
    bookingGraph {
      getProperty(id: $propId) {
        ...propertyFragments
        ...gradingFragments
      }
    }
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          name: title
        }
      }
    }
    allWpMapfeaturesCategory {
      edges {
        node {
          name
          description
        }
      }
    }
    allWpMapfeature {
      edges {
        node {
          ...mapfeatureFragments
        }
      }
    }
  }
`;
