import React, { useContext } from "react"
import styled from "styled-components";
import { gql, useQuery } from "@apollo/client"
import Scrollchor from "react-scrollchor"
import SocialSharing from "../socialSharing/socialSharing"
import Heading from "../../utils/heading";
import { BookingContext } from "../checkout/providers/booking"
import Offers from "./propertyOffers"
import { navigate } from "gatsby"
import { browserDetails } from "../../hooks/propertyHelpers";


const Wrapper = styled.aside.attrs({
  className: "aside",
})`
  padding-top: 1rem;
  text-align:center;

  a {
    text-decoration: none;
  }

  .cta {
    ${props => props.theme.buttons.primary};
    width:100%;
  }

  .reset {
    text-decoration: none;
    color: ${props => props.theme.colors.secondary};
    font-style: italic;
    display: block;
    margin-top: 1rem;
  }

  .bookingInfo {
    ${props => props.theme.buttons.primary};
    width:100%;
    &.disabled {
      opacity:0.5;
      cursor: default;
      pointer-events: none;
    }

      .offerApplied{
        ${props => props.theme.fonts.smallerFontSize};
      }
  }

  .desktopInfo {
    display: none;
    @media ${props => props.theme.mq.md} {
      display: block;

      .title{
        margin: 0 0 1rem 0;
        ${props => props.theme.fonts.primaryFont};
      }

    }
  }

  .mobileInfo {
    display: block;
    @media ${props => props.theme.mq.md} {
      display: none;
    }
  }

  .dates {
    @media ${props => props.theme.mq.md} {
      margin-bottom:1rem;
    }
    div{
      display:flex;
      justify-content: space-between;
      padding:0 1rem;
      &:first-of-type{
        margin-bottom:0.5rem;
      }
    }
    span{
      ${props => props.theme.fonts.smallerFontSize};
    }
  }

`

const SideBar = styled.div.attrs({
  className: "sidebar",
})`
  display: none;

  @media ${props => props.theme.mq.md} {
    display: block;
    position: sticky;
    top: 100px;
  }


  .inner {
    background-color: ${props => props.theme.colors.lightGrey};
    padding: 1rem;
  }

  .socialSharingWrapper{
    margin-top:1rem;
    .socialIcons{
      justify-content:center;
    }
  }


`

const StickyFooter = styled.div.attrs({
  className: "stickyFooter",
})`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: ${props => props.theme.colors.lightGrey};
  z-index: 21;
  box-shadow: 0px -3px 6px #00000029;

  @media ${props => props.theme.mq.sm} {
    padding: 1rem;
  }

  @media ${props => props.theme.mq.md} {
    display: none;
  }
`

const BookingInfo = styled.div.attrs({
  className: "bookingInfo",
})`

`

const PropertyPageSide = props => {
  const { initBooking } = useContext(BookingContext)

  // This query is executed at run time by Apollo.
  const APOLLO_QUERY = gql`
    query getMyPrice($pricequery: PriceQuery) {
      getBookingPrice(query: $pricequery) {
        totalCost
        depositCost
        bookingFee
        balanceDue
        properties {
          id
          available
          message
          accommodation
          discount
        }
      }
    }
  `

  const pricequery = priceQueryBuilder(props.from, props.to, props.propref, props.party);

  // Our Apollo Client query that does the heavy lifting to fetch our data and returns data to loadDynamic handler
  const { data } = useQuery(APOLLO_QUERY, {
    variables: {pricequery},
    skip: !pricequery,
    ssr: false,
    fetchPolicy: "network-only"
   // onCompleted: loadFetchedPrice,
  })

  function priceQueryBuilder(from, to, proprefs, party) {
    var pricequery = {};

    if (from && to) {
      pricequery.when = {}
      pricequery.when.from = from.toISOString()
      pricequery.when.to = to.toISOString()
    } else {
      return null;
    }

    if (proprefs) {
      pricequery.which = {}
      pricequery.which.proprefs = [proprefs.toString()]
    }

    if (party?.adults) {
      pricequery.who = party
    }

    return pricequery;
  }

  function getPrice(){
    return(data?.getBookingPrice?.totalCost
      ? parseInt(data.getBookingPrice.totalCost)
      : null)
  }

  function getDeposit(){
    return(data?.getBookingPrice?.depositCost
      ? parseInt(data.getBookingPrice.depositCost)
      : null)
  }

  function getBalanceDue(){
    return(data?.getBookingPrice?.balanceDue
      ? data.getBookingPrice.balanceDue
      : null)
  }

  const getName = data?.getProperty?.name??'unknown'

  const getImageUrl = data?.getProperty?.images[0]?.url

  const startDateString =
    props.from !== null ? props.from.format("dddd Do MMMM YYYY") : "Arrival"
  const endDateString =
    props.to !== null ? props.to.format("dddd Do MMMM YYYY") : "Departure"
  const ratePrefix = 
    props.from !== null ? "Book now for " : "From "
  const rateSuffix = 
    props.from !== null ? "" : " pw"
  const rateString =
    getPrice()
      ? (
        data?.getBookingPrice?.properties[0].available 
        ? ratePrefix + " £" + getPrice() 
        : data?.getBookingPrice?.properties[0].message
        )
      : ratePrefix + " £" + props.pricerange.lower + " to £" + props.pricerange.upper + rateSuffix

  const headerTxt =
    props.from !== null
      ? "Your dates are available"
      : "Add dates for availability"

  async function book() {
    try {
      await initBooking({
        ref: props.propref,
        propImageUrl: props.propImageUrl,
        propName: props.propName,
        propRef: props.customRef,
        dates: {
          from: props.from.toDate(),
          to: props.to.toDate(),
        },
        guests: props.party,
        extras:[],
    //    price: getPrice(),
    //    deposit: getDeposit(),
    //    due: getBalanceDue(),
        bookingType: "paylater",
        browser: browserDetails(),
      })

      console.log('root');
      navigate(`/checkout`)
    //  window.location.replace(`/checkout`)
    }
    catch (e) {
      console.log("Init Booking failed")
      console.log(e.message)
    }
  }

  const renderBookingInfo = () => {
    if (props.from === null) {
      return (
        <>
          <div className="desktopInfo">

            <Heading type="title" as="h4" color={props => props.theme.colors.primaryFontColor}>
              {headerTxt}
            </Heading>

            <p>{rateString}</p>

          </div>

          <Scrollchor
            className="cta"
            to="#availability"
            animate={{ offset: 0, duration: 600 }}
            disableHistory={true}
          >
            Check Availability
          </Scrollchor>
        </>
      )
    } else {
      return (
        <>
          <div className="desktopInfo">

            <Heading type="title" as="h4" color={props => props.theme.colors.primaryFontColor}>
              {headerTxt}
            </Heading>

            <div className="dates">
              <div>
                <span>Arrival:</span> <span>{startDateString}</span>
              </div>
              <div>
                <span>Departure:</span> <span>{endDateString}</span>
              </div>
            </div>

          </div>

          <BookingInfo onClick={book} disabled={!data?.getBookingPrice?.properties[0].available} >
            <div className="mobileInfo dates">
              <span>Arrival: {startDateString}</span>
              <br />
              <span>Departure: {endDateString}</span>
            </div>
            <strong>{rateString}</strong>
            {data?.getBookingPrice?.properties[0].discount ? <span className="offerApplied"> - Offer Applied</span> : null }
          </BookingInfo>

          <div className="mobileInfo">
            <Scrollchor
              className="reset"
              to="#availability"
              animate={{ offset: 0, duration: 600 }}
              disableHistory={true}
            >
              Change dates
            </Scrollchor>
          </div>
        </>
      )
    }
  }

  return (
    <Wrapper>
      <SideBar>
        <div className="inner">{renderBookingInfo()}</div>
        <Offers offers={props.offers} layout="desktop" />
        <SocialSharing title={props.propName} />
      </SideBar>

      <StickyFooter>{renderBookingInfo()}</StickyFooter>
    </Wrapper>
  )
}

export default PropertyPageSide
