import React from "react"
import styled from "styled-components"
//import Icon from "../../utils/icon"
import IconFacebook from '../../../static/svgs/inline/facebook.svg'
//import IconInstagram from '../../../static/svgs/inline/instagram.svg'
import IconLinkedin from '../../../static/svgs/inline/linkedin.svg'
//import IconTwitter from '../../../static/svgs/inline/twitter.svg'
import IconMail from '../../../static/svgs/inline/mail.svg'
import IconPrint from '../../../static/svgs/inline/print.svg'

import { globalHistory } from "@reach/router"

const Wrapper = styled.div.attrs({
  className: "socialSharingWrapper",
})`

`
const Inner = styled.div`
  .socialIcons {
    display: flex;
  }
  .link {
    margin-right: 1rem;
    border:0;
    background-color:transparent;

    &:last-child {
      margin-right: 0px;
    }
    svg{
        fill: ${props => props.theme.colors.primary};
    }
    &:hover {
      svg{
        fill: ${props => props.theme.colors.secondary};
      }
    }
  }
`

const SocialSharing = props => {

  const name = props.title

  const pageUrl = process.env.GATSBY_WEB_ADDR + globalHistory.location.pathname

  const twitter = "https://twitter.com/home?status=" + name + " - " + pageUrl

  const facebook = "https://www.facebook.com/sharer/sharer.php?u=" + pageUrl + "&t=" + name

  const linkedin = "https://linkedin.com/shareArticle?mini=true&url=" + pageUrl + "&title=" + name

  const email = "mailto:?subject=" + name + "&body=" + pageUrl

  return (
    <Wrapper>
      <Inner>

        <div className="socialIcons">

          <a className="link" href={twitter}
            aria-hidden="true"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <IconTwitter height="1rem" /> */}
          </a>

          <a className="link" href={facebook}
            aria-hidden="true"
            target="_blank"
            rel="noopener noreferrer"
          >
          <IconFacebook height="1rem" />
          </a>

          <a className="link" href={linkedin}
            aria-hidden="true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconLinkedin height="1rem" />
          </a>

          <a className="link" href={email}
            aria-hidden="true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconMail height="1rem" />
          </a>

          <button className="link" onClick={() => window.print()} onKeyDown={() => window.print()}>
            <IconPrint height="1rem" />
          </button>

        </div>


      </Inner>
    </Wrapper>
  )

}

export default SocialSharing