export const browserDetails = () => {

    var offset = new Date().getTimezoneOffset();
    const validDepths = ["1", "4", "8", "15", "16", "24", "32", "48"]
    const browser = {
        challengeWindowSize: "Small",
        language: typeof window !== "undefined" ? window.navigator.language : "en-GB",
        colorDepth: typeof window !== "undefined" ? (validDepths.indexOf(window.screen.colorDepth) !== -1 ? window.screen.colorDepth : "16") : "16",
        screenHeight: typeof window !== "undefined" ? window.screen.height : "768",
        screenWidth: typeof window !== "undefined" ? window.screen.width : "1200",
        tZ: offset < 0 ? `${offset}` : `+${offset}`,
        userAgent: typeof window !== "undefined" ? window.navigator.userAgent : "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.14; rv:67.0) Gecko/20100101 Firefox/67.0",        
    }
    return browser
}
