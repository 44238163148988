
import React from "react"
import styled from "styled-components";
import Heading from "../../utils/heading";

const Wrapper = styled.div`
  margin: 1rem auto;

  div{
      padding:1rem;
      background-color: ${props => props.theme.colors.tertiary};
      border-bottom:3px dotted white;
      text-align: center;

      &:last-of-type{
        border-bottom:0;
      }

      .subTitle{
          margin:0 0 0.25rem 0;
      }
      span{
        ${props => props.theme.fonts.smallerFontSize};
      }
  }

  &.mobile{
    @media ${props => props.theme.mq.md} {
        display:none;
    }
  }

  &.desktop{
    display:none;
        @media ${props => props.theme.mq.md} {
            display:block;
        }
  }
  
`

const Offers = props => {

    //console.log(props)

    function showOffers() {

        if (props?.offers.length){
            return (

                <Wrapper className={props.layout}>

                    <Heading type="title" as="h3" color={props => props.theme.colors.secondary}>
                        Special Offers
                    </Heading>

                    {props.offers.map( (offer, i) => {

                        return (
                            <div key={i}>
                                <Heading type="subTitle" as="h6" color={props => props.theme.colors.secondary}>
                                    Get {offer.discountPercentage}% off a {offer.condition} night stay
                                </Heading>
                                <span>between {offer.startDate} &amp; {offer.endDate}</span>
                            </div>
                        )

                    })}
   
                </Wrapper>

            ) 
        } else {
            return null
        }

    }
    
    return showOffers()

}

export default Offers