import React from "react"
import styled from "styled-components"

import PropertyTile from "../../propertyTile/propertyTile"

const Wrapper = styled.div.attrs({
  className: "propertySliderrWrapper container",
})`
  ${props => props.theme.wrappersContainers.primaryContainer};
  overflow:auto;

`

const TileWrapper = styled.div`

  overflow-x:hidden;
  position:relative;

  .scrollInner{
    overflow-x: auto;
    display:flex;
    padding-bottom:1rem;
    
    ::-webkit-scrollbar {
      height: 1rem;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.colors.primary};
      outline: 1px solid transparent;
    }

    .tileWrapper{
      margin:0 1rem 0 0;
      width:calc(100vw - 4rem);

      @media ${props => props.theme.mq.xs} {
        width:calc(50vw - 2.5rem);
      }

      @media ${props => props.theme.mq.sm} {
        width:calc(50vw - 3rem);
      }

      @media ${props => props.theme.mq.md} {
        width:calc(33.333vw - 3.3rem);
      }

      @media ${props => props.theme.mq.lg} {
        width:calc(25vw - 2.76rem);
      }

      @media ${props => props.theme.mq.xl} {
        width:calc(19.1vw - 3rem);
      }
    }

  }

`

const PropertySlider = props => {

  //get first six properties
  function renderNumber(howMany) {

    var selectedProperties = props.properties && props.properties.slice(0,howMany)

    return (

      selectedProperties && selectedProperties.map((prop, i) =>
        <div className="itemWrapper" key={i} >
          <PropertyTile props={prop} />
        </div>
      )

    )

  }

  return (
    <Wrapper>

        <TileWrapper>
          <div className="scrollInner">
            {renderNumber(props.maxno)}
          </div>
        </TileWrapper>

    </Wrapper>
  )
}

export default PropertySlider