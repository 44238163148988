import React from "react";
import styled from "styled-components";
import Icon from "../../utils/icon";
import Heading from "../../utils/heading"
import PropertyGrading from "./propertyGrading"

const Header = styled.div`

    .title{
        margin-top:0;
        ${props => props.theme.fonts.h3};
    }

    .location {
        font-weight: bold;
    }

`

const Details = styled.div`

    .topLine{
        margin:0 -1rem;
        padding:2rem 0;
        display:flex;
        flex-direction:column;

        @media ${props => props.theme.mq.xs} {
            flex-direction: row;
            flex-wrap: wrap;
        }

        span{
            margin:0 1rem 1rem 1rem;
            @media ${props => props.theme.mq.xs} {
                text-align:center;
                padding:0.4rem 0.75rem;
                border: 1px solid ${props => props.theme.colors.lightGrey};
            }

            @media ${props => props.theme.mq.lg} {
                padding:0.5rem 1rem;
            }
        }

        .svgWrapper {
            display:inline-block;
            margin-right:0.5rem;
            position: relative;
            top: 4px;
            fill: ${props => props.theme.colors.secondary};
        }

    }

    .bottomLine{
        display:flex;
        flex-direction:column;

        .makeSmall{
            ${props => props.theme.fonts.smallerFontSize};
        }
    }

`


const PropertyDetails = ({ data, bgData, attrs }) => {

    //return attrs
    const allAttrs = bgData.attributes.map((node, i) => {
        return node.name
    })

    return (

        <>

            <Header>

                <Heading type="title" as="h1" color={props => props.theme.colors.secondary}>
                    {bgData.name}
                </Heading>

                <div className="location">{bgData.where.town}, {bgData.where.county}</div>

            </Header>


            <Details>

                <div className="topLine">

                    <span className="lowerIcon"><Icon name="sleeps" height="1.3rem" />Sleeps <strong>{bgData.sleeps}</strong></span>
                    <span className="lowerIcon"><Icon name="beds" height="1.3rem" />Bedrooms <strong>{bgData.bedrooms}</strong></span>
                    <span className="lowerIcon"><Icon name="bathroom" height="1.3rem" />Bathrooms <strong>{bgData.bathrooms}</strong></span>
                    <span className="lowerIcon"><Icon name="pets-welcome" height="1.3rem" />Pets <strong>{bgData.pets ? "welcome" : "not allowed"}</strong></span>

                    {allAttrs.includes("Car Parking") && (
                        <span className="lowerIcon">
                            <Icon name="parking" height="1.3rem" /> Parking
                        </span>
                    )}

                    {allAttrs.includes("Garden") && (
                        <span className="lowerIcon">
                            <Icon name="garden" height="1.3rem" /> Garden
                        </span>
                    )}

                    {allAttrs.includes("Internet") && (
                        <span className="lowerIcon">
                            <Icon name="wifi" height="1.3rem" /> WiFi
                        </span>
                    )}

                </div>

                <PropertyGrading attrs={attrs} pros={data.bookingGraph.getProperty?.pros} cons={data.bookingGraph.getProperty?.cons} />

                <div className="bottomLine">
                    <span>Changeover: <strong>{bgData.changeover}</strong></span>
                    <span>Property Reference Number: <strong>{bgData.propRef}</strong></span>
                    <span className="makeSmall">WP-{data.wpProperty.databaseId} / API-{bgData.apiRef}</span>
                </div>

            </Details>


        </>

    )

}


export default PropertyDetails;