import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import Img from "gatsby-image"
import Icon from "../../utils/icon";
import WishList from "../wishlist/wishlist"
import Slider from "react-slick"
import "../../theme/components/slickSliderStyles/slick.scss"
import "../../theme/components/slickSliderStyles/slick-theme.scss"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';



const Wrapper = styled.div`
    ${props => props.theme.wrappersContainers.primaryWrapper};
    margin-top:1.5rem;
    display:flex;
    justify-content: space-between;

    .wishlist{
        z-index: 1;
        position: absolute;
        top: 1rem;
        right: 0.3rem;
        @media ${props => props.theme.mq.md} {
            right: 5.3rem;
        }
        
        .svgWrapper{
            fill:rgba(255, 255, 255, 0.9);
        }
    }

    .lightBoxButton{

        display:flex;
        align-items:center;
        border: 0;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 1rem;
        position: absolute;
        bottom: 1rem;
        right: 50%;
        transform: translate(50%,0);
        border-radius:50%;
        cursor: pointer;
    }

    .gallerySliderWrapper{
        position: relative;
        box-sizing: content-box;
        width: 100%;

        @media ${props => props.theme.mq.md} {
            min-width:620px;
            padding-right:4rem;
        }

        .slick-slider{

            .slick-next, .slick-prev {
                z-index: 1;
                background-color: rgba(255, 255, 255, 0.9);
                border-radius: 50%;
                width:2rem;
                height:2rem;
            
                &:before{
                    content:"";
                }
            }

            .slick-next {
                right: 1rem;
                .svgWrapper{
                    margin-right: -2px;
                }
            }

            .slick-prev {
                left: 1rem;
                .svgWrapper{
                    margin-left: -2px;
                }
            }

        }

    }

    .navSliderWrapper{
        width: 100%;
        max-width:335px;
        display:none;

        @media ${props => props.theme.mq.md} {
            display:block;
        }

        .slick-list {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 50vh;
        }

        .slick-track{
            width:100%!important;
        }
    }

`

const Slide = styled.div.attrs({
    className: "gallery",
})`
    position: relative;
    height: 40vh;
    min-height:18rem;

    @media ${props => props.theme.mq.sm} {
        height: 50vh;
    }

    .justImg{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

`

const Nav = styled.div.attrs({
    className: "nav",
})`
    position: relative;
    width: calc(50% - 1.5rem)!important;
    margin: 0 .75rem 1.5rem .75rem;
    height:4rem;

    @media ${props => props.theme.mq.lg} {
        height:6rem;
    }

    .justImg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        cursor: pointer;
    }
`


function NextArrow(props) {
    const { className, onClick } = props
    return (
        <button className={className} onClick={onClick}>
            <Icon name="slider-right" width="1rem" />
        </button>
    )
}

function PrevArrow(props) {
    const { className, onClick } = props
    return (
        <button className={className} onClick={onClick}>
            <Icon name="slider-left" width="1rem" />
        </button>
    )
}

const settings = {
    lazyLoad: true,
    infinite: true,
    dots: false,
    arrows: true,
    fade: false,
    slidesToScroll: 1,
    pauseOnHover: true,
    adaptiveHeight: false,
    mobileFirst: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
}

const navSettings = {
    infinite: false,
    slidesToShow: 99,
    focusOnSelect: true,
    draggable: false,
    swipe: false,
}

const Gallery = ({ content, bgData }) => {

    //lightbox
    const slides = content || []

    //if slider has a gallery
    function hasGallery() {

        var noImages = ""

        var imageUrls = slides.filter(

            edge => edge

        )

        if (imageUrls.length < 1 || imageUrls === undefined) {

            return noImages

        } else {

            return imageUrls.map(image => `${image.url}?width=1920&scale=both&quality=70`)

        }

    }

    const imageUrls = hasGallery()


    //lightbox state
    const [lightboxState, setLightboxState] = useState({
        photoIndex: 0,
        isOpen: false,
    })

    const { photoIndex, isOpen } = lightboxState


    //as nav for state
    let slider1
    let slider2

    const [sliderState, setSliderState] = useState({
        nav1: null,
        nav2: null
    })

    useEffect(() => {

        setSliderState(setState => ({
            ...setState,
            nav1: slider1,
            nav2: slider2
        }))

    }, [slider1, slider2])

    return (

        <Wrapper>

            <div className="gallerySliderWrapper">

                <WishList {...bgData} />

                <Slider {...settings}
                    asNavFor={sliderState.nav1}
                    ref={slider => (slider2 = slider)}
                    slidesToShow={1}
                    arrows={true}
                    fade={true}
                    className="gallerySlider"
                >
                    {content.length && content.map((item, i) =>
                        <Slide key={i}>
                            {item.localFile ? (
                                <div className="imageWrapper">
                                    <Img alt={item.description} fluid={item.localFile.childImageSharp.fluid} />
                                </div>
                            ) : (
                                    <img alt={item.description} src={`${item.url}?width=1200&scale=both&quality=70`} width="1200" className="justImg" />
                                )}
                        </Slide>
                    )}
                </Slider>

                <button className="lightBoxButton" type="button" onClick={() => setLightboxState(setIt => ({ ...setIt, isOpen: true }))}><Icon name="expand" height="1rem" /></button>

            </div>

            <div className="navSliderWrapper">
                <Slider {...settings}
                    {...navSettings}
                    asNavFor={sliderState.nav2}
                    ref={slider => (slider1 = slider)}
                    className="navSlider"
                >
                    {content.length && content.map((item, i) =>
                        <Nav key={i}>
                            {item.localFile ? (
                                <div className="imageWrapper">
                                    <Img alt={item.description} fluid={item.localFile.childImageSharp.fluid} />
                                </div>
                            ) : (
                                    <img alt={item.description} src={`${item.url}?width=150&scale=both&quality=70`} width="150" className="justImg" />
                                )}
                        </Nav>
                    )}
                </Slider>
            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={imageUrls[photoIndex]}
                    nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
                    prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
                    onCloseRequest={() => setLightboxState({ photoIndex: 0, isOpen: false })}
                    onMovePrevRequest={() =>
                        setLightboxState(setIt => ({
                            ...setIt,
                            photoIndex: (photoIndex + imageUrls.length - 1) % imageUrls.length,
                        }))
                    }
                    onMoveNextRequest={() =>
                        setLightboxState(setIt => ({
                            ...setIt,
                            photoIndex: (photoIndex + 1) % imageUrls.length,
                        }))
                    }
                />
            )}

        </Wrapper>

    )
}

export default Gallery