import React from "react";
import styled from "styled-components";

const Wrapper = styled.div.attrs({
    className: "vrTour",
})`
    margin:3rem 0;
`

const VRTour = ({ tour }) => {
    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{ __html: tour }} />
        </Wrapper>
    )
}

export default VRTour