import React, { useState } from "react";
import styled from "styled-components";
// import { InView } from "react-intersection-observer";
import StickyNavigation from "./stickyNavigation"
import ReadMore from "../../utils/readMore";
import AvailabilityPicker from "../datepicker/availabilityPicker"
import moment from "moment";
import PropertyPageSide from "../propertyPage/propertyPageSide"
import Reviews from "../reviews/reviews"

import PropertyDetails from "./propertyDetails"
import Map from "../googleMap/mapwrapper"
import Heading from "../../utils/heading"
import Gallery from "./propertyGallery"
import Attributes from "./propertyAttributes"
import PropertySelector from "../property/propertySelector/propertySelector"
import VRTour from "../vrTour/vrTour"
import filterHelpers from "../../utils/filterHelpers"
import Offers from "./propertyOffers"

//import RelatedProperties from "../relatedProperties/relatedProperties"


const Wrapper = styled.div`
  margin: 0 auto;
  
`

const Inner = styled.div`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  display:flex;

  main {

      box-sizing: content-box;
      width: 100%;
      padding-top: 0.8rem;
      padding-bottom:5rem;

      @media ${props => props.theme.mq.md} {
          min-width:620px;
          padding-right:4rem;
          padding-top: 2rem;
          
      }

  }

  aside{
    @media ${props => props.theme.mq.md} {
      width: 100%;
      max-width:335px;
    }
  }

`

const Section = styled.div`
  padding: 1rem 0;

  .title{
    ${props => props.theme.fonts.textIndent};
    ${props => props.theme.fonts.h1};
    margin: 2rem 0;
  }

`

const MapWrapper = styled.div`
  height: 500px;

  @media ${props => props.theme.mq.sm} {
    height: 610px;
  }
`

const CalendarWrapper = styled.div`
  margin-top: 1rem;
  width: fit-content;

  margin-left: -0.75rem;
  margin-right: -0.75rem;

  @media screen and (min-width: 360px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 640px) {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }

  @media screen and (min-width: 641px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const CalendarKey = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  div {
    width: 33%;
    @media ${props => props.theme.mq.xs} {
      width: auto;
    }
  }

  span {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 5px;
    margin-right: 0.5rem;
  }

  .available {
    span {
      border: 1px solid #cacccd;
      background-color: white;
    }
  }

  .booked {
    span {
      border: 1px solid #cacccd;
      background-color: #cacccd;
    }
  }

  /* .changeover {
    span {
      border: 1px solid rgba(${props => props.theme.colors.secondaryRGB} ,0.3);
      background-color: rgba(${props => props.theme.colors.secondaryRGB} ,0.3);
    }
  } */

  .selected {
    span {
      border: 1px solid #5c7faa;
      background-color: #5c7faa;
    }
  }
`

const PropertyPageInner = ({ data }) => {

  // let initialBooking = {
  //   when_from: null,
  //   when_to: null,
  //   who_adults: 2,
  //   who_children: 0,
  //   who_infants: 0,
  //   who_pets: 0
  // };
  // const storedState = typeof sessionStorage !== `undefined` && sessionStorage.filterState
  // let storedFilter = storedState ? JSON.parse(storedState) : initialBooking

  const storedFilter = filterHelpers.currentFilterState();
  //booking state
  const [propertyBookingState, setPropertyBookingState] = useState(storedFilter)

  // add default object to stop build failing when bgData isn't present
  const bgData = data.bookingGraph.getProperty || {
    where: {
      coords: {},
    },
    description: {},
    attributes: [],
  };
  // const bgAvailability = {
  //   which: {
  //     proprefs: [data.bookingGraph.getProperty.propRef.toString()]
  //   },
  //   when: {
  //     from: moment().toISOString(),
  //     to: moment().add(18, 'months').toISOString()
  //   }
  // }
  const wpMapCatgs = data.allWpMapfeaturesCategory.edges
  const wpMapFeatures = data.allWpMapfeature.edges
  const featureMarkers = wpMapFeatures.map(feature => {
    var feat = feature.node
    var featMeta = feat.mapFeatureMeta
    featMeta.title = feat.title
    featMeta.content = feat.content
    return {
      category: feat.mapfeaturesCategories.nodes[0].name,
      propRef: feat.databaseId,
      latlng: { lat: featMeta.mapLocation.latitude, lng: featMeta.mapLocation.longitude },
      info: featMeta,
      selected: false
    }
  })
  featureMarkers.push({ category: "Property", propRef: bgData.apiRef, latlng: { lat: bgData.where.coords.lat, lng: bgData.where.coords.lon }, info: bgData })
  //console.log(featureMarkers)


  const ignore = ["Changeover Day", "Floor level", "Bedrooms", "Bathrooms", "Theme", "Pet Friendly", "Sleeps"]
  const attrs = bgData.attributes.reduce((r, attr) => {
    if (ignore.indexOf(attr.category) === -1) {
      if (r[attr.category] === undefined) r[attr.category] = []
      r[attr.category].push(attr);
    }
    return r;
  }, {})


  //when filter
  function whenFilter(startDate, endDate) {
    if (startDate !== null)
      setPropertyBookingState(setBooking => ({
        ...setBooking,
        when_from: startDate,
      }))
    if (endDate !== null)
      setPropertyBookingState(setBooking => ({
        ...setBooking,
        when_to: endDate,
      }))
  }

  function searchQueryBuilder(location) {
    var searchquery = {};

    if (location) {
      searchquery.where = {}
      searchquery.where.location = location.toString()
    } else {
      return null;
    }
    return searchquery;
  }

  const searchquery = searchQueryBuilder(bgData.locationId)

  //is there WP gallery images
  const whichGallery = () => {
    if (bgData.gallery != null) {
      return bgData.gallery.length ? bgData.gallery : []
    } else {
      return bgData.images !== null ? bgData.images : []
    }
  }

  return (
    // <InView>
    //   {({ inView, ref, entry }) => (
    <>

      <StickyNavigation bgData={bgData} />

      <Wrapper>

        <div >
          <Gallery content={whichGallery()} bgData={bgData} />
        </div>

        <Inner>
          <main>

            <PropertyDetails data={data} bgData={bgData} attrs={attrs} />

            <Offers offers={bgData.offers} layout="mobile" />

            <Section id="overview">

              <Heading type="title" as="h3" color={props => props.theme.colors.tertiary}>
                Overview
              </Heading>

              <VRTour tour={bgData.vrtour} />

              <ReadMore content={bgData.description.long} height="150" uniqueId="Overview" />

            </Section>

            <Section id="particulars">

              <Heading type="title" as="h3" color={props => props.theme.colors.tertiary}>
                Facts &amp; Features
              </Heading>

              <Attributes attrs={attrs} />

            </Section>

            <Section id="location">

              <Heading type="title" as="h3" color={props => props.theme.colors.tertiary}>
                Location
              </Heading>

              <MapWrapper>
                <Map
                  apikey={process.env.GATSBY_MAPS_KEY}
                  markers={featureMarkers}
                  zoom={14}
                  showcats={wpMapCatgs.map((catg, i) => { return { name: catg.node.name, desc: (catg.node.description || catg.node.name), selected: false } })}
                />
              </MapWrapper>

              <Heading as="h4" color={props => props.theme.colors.secondary}>
                Travel information to {bgData.where.county}
              </Heading>

              <div dangerouslySetInnerHTML={{ __html: data.wpProperty.propertyLocations.nodes[0].location_directions.directions }} />

            </Section>

            <Section id="availability">

              <Heading type="title" as="h3" color={props => props.theme.colors.tertiary}>
                Availability
              </Heading>

              {!data.bookingGraph.getProperty.status.withdrawn && !data.bookingGraph.getProperty.status.disabled &&
                <CalendarWrapper>
                  <AvailabilityPicker
                    propRef={data.bookingGraph.getProperty.apiRef.toString()}
                    initialStartDate={
                      propertyBookingState.when_from !== null
                        ? moment(propertyBookingState.when_from)
                        : null
                    }
                    initialEndDate={
                      propertyBookingState.when_to !== null
                        ? moment(propertyBookingState.when_to)
                        : null
                    }
                    onChange={whenFilter}
                  //                      onReset={resetDates}
                  />
                </CalendarWrapper>
              }
              {!data.bookingGraph.getProperty.status.withdrawn && !data.bookingGraph.getProperty.status.disabled &&
                <CalendarKey>
                  <div className="available"><span></span>Available</div>
                  <div className="booked"><span></span>Booked</div>
                  {/* <div className="changeover"><span></span>Changeover</div> */}
                  <div className="selected"><span></span>Selected</div>
                </CalendarKey>
              }
              {(data.bookingGraph.getProperty.status.withdrawn || data.bookingGraph.getProperty.status.disabled) &&
                <div>Bookings are not currently being taken on this property</div>
              }
            </Section>

            {bgData.reviews &&
              <Section id="reviews">

                <Heading type="title" as="h3" color={props => props.theme.colors.tertiary}>
                  Reviews
                </Heading>

                <Reviews
                  reviews={bgData.reviews ? bgData.reviews : []}
                  merchant={
                    data.allSite.nodes[0].siteMetadata.title ? data.allSite.nodes[0].siteMetadata.title : ""
                  }
                  rating={bgData.rating}
                />{" "}
                { }
              </Section>
            }

            <Section id="related">
              <Heading type="title" as="h3" color={props => props.theme.colors.tertiary}>
                Related Properties
              </Heading>

              <PropertySelector searchquery={searchquery} excludeIds={[bgData.apiRef]} maxno={8} />
            </Section>

          </main>

          {!data.bookingGraph.getProperty.status.withdrawn && !data.bookingGraph.getProperty.status.disabled &&
            <PropertyPageSide
              from={
                propertyBookingState.when_from !== null
                  ? moment(propertyBookingState.when_from)
                  : null
              }
              to={
                propertyBookingState.when_to !== null
                  ? moment(propertyBookingState.when_to)
                  : null
              }
              pricerange={bgData.prices.priceBounds}
              propref={bgData.apiRef}
              customRef={bgData.propRef}
              propName={bgData.name}
              propImageUrl={bgData?.images[0]?.url}
              offers={bgData.offers}
              party={
                {
                  adults: propertyBookingState.who_adults,
                  children: propertyBookingState.who_children,
                  infants: propertyBookingState.who_infants,
                  pets: propertyBookingState.who_pets,
                }
              }
            />
          }
        </Inner>
      </Wrapper>
    </>
    //   )}
    // </InView>
  );
};

export default PropertyPageInner;
